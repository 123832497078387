import React from "react";
import { /* Container, */ Image /* , Input  */ } from "react-bootstrap";
/* import { Link } from "react-router-dom"; */
import { Homebanner } from "../../assets/images";
import "./Home.css";

const HomeDu = () => {
  return (
    <div className="home_banner" id="home">
      <Image src={Homebanner} />
      <div className="home_info">
        <h2 className="main-title">
          Youpi<span>24</span> GmbH <br /> IT Consulting & Solutions
        </h2>
        <p className="title-text">
          Youpi24 ist Ihr Spezialist für IT-Beratung
          und Entwicklung erstklassiger Web-, Mobil- und
          Unternehmensanwendungen. Unsere Mission und unser Hauptantrieb sind
          es, mit wertvollen Dienstleistungen und Produkten an der Spitze der
          Innovation zu bleiben, um die Geschäftsprozesse unserer Kunden in
          diversen Bereichen wie Banken / Finanzen, Versicherungen, E-Govenance,
          Verlagswesen, Gaming/Entertainment, E-Commerce und Online-Marketing zu
          verbessern. Wir beraten und entwickeln für unsere Kunden
          state-of-the-art Software Lösungen, und bieten sowohl skalierbare
          Unternehmenslösungen als auch Web 2.0- oder Blockchain-basierte Web
          3.0-Lösungen.
        </p>
      </div>
    </div>
  );
};
export default HomeDu;

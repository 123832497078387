import React from "react";
import Company from "../Company/Company";
import Contact from "../Contact/Contact";
import Header from "../Header/Header";
import Home from "../Home/Home";
import Imprint from "../Imprint/Imprint";
import Portfolio from "../Portfolio/Portfolio";
import "./HomeEn.css";


const HomeEn = () => {
  return (
    <div className='home-banner text-center'>
    <Header />
    <Home />
    <Company />
    <Portfolio />
    <Contact />
    <Imprint />
  </div>
  );
};
export default HomeEn;

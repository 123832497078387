import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import { Navbar, Container, Nav, NavDropdown, Image } from "react-bootstrap";
import { Dutch, France, NavbarBrand } from "../../assets/images";
import { America } from "../../assets/images/";
import StickyHeader from "../../utils/StickyHeader";
import { HashLink } from 'react-router-hash-link';
import "./Header.css";
import {Link as ScrollLink } from "react-scroll";

const Header = () => {
  const { isSticky } = StickyHeader();
  console.log({ isSticky });

  const handelNavLinkClick = () =>{
    document.querySelector(".navbar-collapse").classList.remove("show");
    document.querySelector(".navbar-toggler").classList.add("collapsed");
  }
  return (
    // <header>Header</header>
    <Navbar
      collapseOnSelect 
      className={cx("test", { "temp-class": isSticky })}
      expand="lg"
    >
      <Container>
        <Navbar.Brand to="/">
          <Image src={NavbarBrand} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <ScrollLink onClick={handelNavLinkClick} className="nav-link " activeClass="active" spy={true} to="home">Home</ScrollLink>
            <ScrollLink onClick={handelNavLinkClick} className="nav-link " activeClass="active" spy={true} to="company">Company</ScrollLink>
            <ScrollLink onClick={handelNavLinkClick} className="nav-link " activeClass="active" spy={true} to="portfolio">Portfolio</ScrollLink>
            <ScrollLink onClick={handelNavLinkClick} className="nav-link " activeClass="active" spy={true} to="contact">Contact</ScrollLink>
            <ScrollLink onClick={handelNavLinkClick} className="nav-link " activeClass="active" spy={true} to="imprint">Imprint</ScrollLink>
            {/* <Nav.Link activeClass="active" spy={true} href="#home">Home</Nav.Link>
            <Nav.Link activeClass="active" spy={true} href="#company">Company</Nav.Link>
            <Nav.Link activeClass="active" spy={true} href="#portfolio">Portfolio</Nav.Link>
            <Nav.Link activeClass="active" spy={true} href="#contact">Contact</Nav.Link>
            <Nav.Link activeClass="active" spy={true} href="#imprint">Imprint</Nav.Link> */}
            <NavDropdown
              title={
                <span>
                  <span className="me-2">
                    <Image src={America} />
                  </span>
                  En
                </span>
              }
              id="basic-nav-dropdown"
            >
              <Link to="/HomeFr">
                <span className="me-2">
                  <Image src={France} />
                </span>{" "}
                FR
              </Link>
              <Link to="/HomeDu">
                <span className="me-2">
                  <Image src={Dutch} />{" "}
                </span>
                DE
              </Link>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;

import React from "react";
import { /*  Container, */ Image, NavLink } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { Test } from "../../assets/images";
import "./Imprint.css";

const Imprint = () => {
  return (
    <div className="imprint_banner" id="imprint">
      <Image src={Test} />
      <div className="home_info">
        <h2 className="main-title heading">Imprint</h2>
        <ul className="location-details">
          <li className="location-text d-flex">
            <Icon icon="ci:location" />
            <p>
              Youpi24 GmbH <br />
              Gewerbestrasse 18 <br />
              77694 Kehl
            </p>
          </li>
          <li>Headquarters : Kehl, Germany</li>
          <li className="d-flex align-items-center">
            E-mail :<NavLink to="/">info@youpi24.com</NavLink>
          </li>
          <li className="d-flex align-items-center">
            web :<NavLink to="/">www.youpi24.com</NavLink>
          </li>
          <li>Tel.: +49 7851 7951 463</li>
          <li>Representative Managing Director : Hugues Nkouatchet Wandji</li>
          <li>Trade register number : HRB 216376</li>
          <li>Tax number : 25/208/22869</li>
          <li>Sales tax ID: DE276927656</li>
        </ul>

        <div  className="soical-wrap">
          {/*<ul className="social-media d-flex">*/}
          {/*  <li>*/}
          {/*    <NavLink to="/">*/}
          {/*      <Icon icon="bi:medium" />*/}
          {/*    </NavLink>*/}
          {/*  </li>*/}
          {/*  <li>*/}
          {/*    <NavLink to="https://twitter.com/Youpi24Info">*/}
          {/*      <Icon icon="ant-design:twitter-outlined" />*/}
          {/*    </NavLink>*/}
          {/*  </li>*/}
          {/*  <li>*/}
          {/*    <NavLink to="/">*/}
          {/*      <Icon icon="akar-icons:discord-fill" />*/}
          {/*    </NavLink>*/}
          {/*  </li>*/}
          {/*</ul>*/}
          <p className="text-white">© Copyright Youpi24 GmbH </p>
        </div>
      </div>
    </div>
  );
};
export default Imprint;

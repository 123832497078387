import React from "react";
import CompanyDu from "../Company/CompanyDu";
import ContactDu from "../Contact/ContactDu";
import HeaderDu from "../Header/HeaderDu";
import HomeDu from "../Home/HomeDu";
import ImprintDu from "../Imprint/ImprintDu";
import PortfolioDu from "../Portfolio/PortfolioDu";
import "./HomeDuPage.css";

const HomeDuPage = () => {
  return (
    <div className="home-banner text-center">
      <HeaderDu />
      <HomeDu />
      <CompanyDu />
      <PortfolioDu />
      <ContactDu />
      <ImprintDu />
    </div>
  );
};
export default HomeDuPage;

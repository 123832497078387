import React from "react";
import CompanyFr from "../Company/CompanyFr";
import ContactFr from "../Contact/ContactFr";
import HeaderFr from "../Header/HeaderFr";
import HomeFr from "../Home/HomeFr";
import ImprintFr from "../Imprint/ImprintFr";
import PortfolioFr from "../Portfolio/PortfolioFr";
import "./HomeFrPage.css";


const HomeFrPage = () => {
  return (
    <div className='home-banner text-center'>
    <HeaderFr />
    <HomeFr />
    <CompanyFr />
    <PortfolioFr />
    <ContactFr />
    <ImprintFr />
  </div>
  );
};
export default HomeFrPage;

import React from "react";
/* import { Container, Image, Input } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Test } from "../../assets/images"; */
import "./Company.css";

const CompanyFr = () => {
  return (
     <div className="company_banner" id="company">
      <div className="company_info">
      <h2 className="main-title">L’entreprise</h2>
      <p className="title-text mb-3">Fort de notre expertise glanée sur plus de 2 décennies d’expérience dans le développement d’applications diverses, notre ambition demeure de combler nos clients avec des solutions innovantes qui apportent une réelle valeur ajoutée à leurs fonctions opératives.
</p>
      <p className="title-text mb-3">Nous fournissons des applications très innovantes à nos clients B2B, que ce soit dans le cadre de nos services de conseil, ou en tant que produits internes sous forme d'applications standard. Dans le domaine B2B, nous soutenons nos clients dans leurs efforts de transformation numérique, soit en ajoutant des ressources directement à leurs équipes informatiques, soit en prenant l'entière responsabilité du développement de tout ou partie de leurs applications métier de base. Dans le domaine du B2C, nous opérons des partenariats et apportons des contributions notables pour délivrer au grand public des logiciels disruptifs dans le web 2.0 et le web 3.0.
</p>
      <p className="title-text">La pleine satisfaction des utilisateurs de nos produits est notre priorité absolue. C’est pourquoi nous mettons toute notre expertise et notre énergie à produire des applications de haute qualité et aussi à offrir le meilleur service à nos clients pour les accompagner dans l’utilisation de ces applications.
</p>
      </div>
     </div>
  );
};
export default CompanyFr;

import React from "react";
/* import { Container, Image, Input } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Test } from "../../assets/images"; */
import "./Company.css";

const CompanyDu = () => {
  return (
     <div className="company_banner" id="company">
      <div className="company_info">
      <h2 className="main-title">Das Unternehmen</h2>
      <p className="title-text mb-3">Mit über 2 Jahrzehnten Erfahrung in der Entwicklung verschiedener Anwendungen ist es immer unser Anspruch, unsere Kunden mit state-of-the-art Software Lösungen zu begeistern und für sie einen echten Mehrwert in der Abwicklung ihrer Geschäfte zu schaffen.
</p>
      <p className="title-text mb-3">Wir liefern hochinnovative Anwendungen für unsere B2B-Kunden, sei es im Rahmen unserer Beratungsleistungen oder als Inhouse-Produkte in Form von Standardanwendungen. Im B2B-Geschäft unterstützen wir unsere Kunden bei der digitalen Transformation, indem wir entweder ihre IT-Teams direkt mit Ressourcen verstärken oder die volle Verantwortung für die Entwicklung einiger oder aller ihrer Kerngeschäftsanwendungen übernehmen. Im B2C-Bereich arbeiten wir in Partnerschaften und leisten einen wichtigen Beitrag zur Bereitstellung von bahnbrechender Web 2.0- und Web 3.0-Software für den allgemeinen Gebrauch.
</p>
      <p className="title-text">Die Zufriedenheit unserer B2B und B2C Kunden hat bei uns oberste Priorität. Deshalb setzen wir uns ein, um qualitativ hochwertige Anwendungen zu entwickeln und unsere Kunden die beste Unterstützung bei der Verwendung der Software anzubieten.
</p>
      </div>
     </div>
  );
};
export default CompanyDu;

import { useState, useEffect, useCallback } from "react";
const StickyHeader = (defaultSticky = false) => {
  const [isSticky, setIsSticky] = useState(defaultSticky);

  const toggleSticky = useCallback(
    ({ pageYOffset }) => {
      if (pageYOffset > 0) {
        !isSticky && setIsSticky(true);
      } else {
        isSticky && setIsSticky(false);
      }
    },
    [isSticky]
  );
  useEffect(() => {
    const handleScroll = () => {
        console.log({ data : window })
      toggleSticky(window);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [toggleSticky]);
  
  return { isSticky };
};
export default StickyHeader;
import React from "react";
import cx from "classnames";
import { Navbar, Container, Nav, NavDropdown, Image } from "react-bootstrap";
import { Dutch, France, NavbarBrand } from "../../assets/images";
import { America } from "../../assets/images";
import { Link } from "react-router-dom";
import StickyHeader from "../../utils/StickyHeader";
import "./Header.css";
import {Link as ScrollLink } from "react-scroll";

const HeaderDu = () => {
  const { isSticky } = StickyHeader();
  console.log({ isSticky });
  
  const handelNavLinkClick = () =>{
    document.querySelector(".navbar-collapse").classList.remove("show");
    document.querySelector(".navbar-toggler").classList.add("collapsed");
  }
  return (
    // <header>Header</header>
    <Navbar
      collapseOnSelect
      className={cx("test", { "temp-class": isSticky })}
      expand="lg"
    >
      <Container>
        <Navbar.Brand to="/">
          <Image src={NavbarBrand} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <ScrollLink onClick={handelNavLinkClick} className="nav-link" activeClass="active" spy={true} to="home">Home</ScrollLink>
            <ScrollLink onClick={handelNavLinkClick} className="nav-link" activeClass="active" spy={true} to="company">Das Unternehmen</ScrollLink>
            <ScrollLink onClick={handelNavLinkClick} className="nav-link" activeClass="active" spy={true} to="portfolio">Portfolio</ScrollLink>
            <ScrollLink onClick={handelNavLinkClick} className="nav-link" activeClass="active" spy={true} to="contact">Kontakt</ScrollLink>
            <ScrollLink onClick={handelNavLinkClick} className="nav-link" activeClass="active" spy={true} to="imprint">Impressum</ScrollLink>
            <NavDropdown
              title={
                <span>
                  <span className="me-2">
                    <Image src={Dutch} />
                  </span>
                  De
                </span>
              }
              id="basic-nav-dropdown"
            >
              <Link to="/HomeFr">
                <span className="me-2">
                  <Image src={France} />{" "}
                </span>{" "}
                FR
              </Link>
              <Link to="/">
                <span className="me-2">
                  <Image src={America} />{" "}
                </span>
                EN
              </Link>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default HeaderDu;

import React from "react";
import { /* Container, */ Image, /*  Input, */ NavLink } from "react-bootstrap";
/* import { Link } from "react-router-dom"; */
import { Portfoliobg } from "../../assets/images";
import "./Portfolio.css";

const PortfolioFr = () => {
  return (
    <div className="portfolio_banner" id="portfolio">
      <Image src={Portfoliobg} />
      <div className="home_info">
        <h2 className="main-title">Portfolio</h2>
        <p className="title-text">
          Avec sa section Conseil, Youpi24 IT Solutions & Consulting présente
          des références solides avec plusieurs projets d’envergure réalisés
          pour des clients dans divers domaines tels que la Banque/Finance, les
          Assurances, le Commerce en ligne, le Marketing, l’édition et la gouvernance.
        </p>
        <p className="title-text">
          Au delà de nos activités de conseil, nous restons ouverts à tout partenariat visant à tirer profit de notre expérience variée pour fournir des solutions B2C spécifiques en e-commerce, dans l‘éducation, la gouvernance et le divertissement. Plusieurs partenaires ont déjà bénéficié de cette expertise. Nous pouvons citer un cas actuel où nous coordonnons les efforts de développement du protocole décentralisé<a href="https://www.noracle.io" target="_blank" className="ms-2">NORACLE</a>, utilisé par des applications de prédictions et d’aide à la décision dans la finance, les jeux et le métavers.
        </p>
      </div>
    </div>
  );
};
export default PortfolioFr;

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import HomeEn from "./components/HomeEn/HomeEn";
import HomeFrPage from "./components/HomeFr/HomeFrPage";
import HomeDuPage from "./components/HomeDu/HomeDuPage";
import { ToastContainer } from "react-toastify";
import { Routes, Route } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Routes>
        <Route path="/" element={<HomeEn />} />
        <Route path="/HomeDu" element={<HomeDuPage />} />
        <Route path="/HomeFr" element={<HomeFrPage />} />
      </Routes>
    </div>
  );
}

export default App;

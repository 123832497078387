import React from "react";
import { /* Container, */ Image/* , Input  */} from "react-bootstrap";
/* import { Link } from "react-router-dom"; */
import { Homebanner } from "../../assets/images";
import "./Home.css";

const Home = () => {
  return (
     <div className="home_banner" id="home">
      <Image src={Homebanner} />
      <div className="home_info">
      <h2 className="main-title">Youpi<span>24</span> GmbH <br/> IT Consulting & Solutions</h2>
      <p className="title-text">Youpi24 is your specialist in IT consulting and development of cutting-edge Web, mobile and enterprise applications. Our mission and main driver are to remain at the forefront of innovation with valuable services and products, to enhance business processes for our customers in various sectors such as banking / finance, insurance, publishing, gaming / entertainment, e-commerce, online marketing and e-governance. We advise and develop state-of-the-art software solutions for our customers, providing both scalable enterprise and mainstream web 2.0 or blockchain powered web 3.0 solutions.</p>
      </div>
     </div>
  );
};
export default Home;

import React from "react";
import { /* Container, */ Image /* , Input  */ } from "react-bootstrap";
/* import { Link } from "react-router-dom"; */
import { Homebanner } from "../../assets/images";
import "./Home.css";

const HomeFr = () => {
  return (
    <div className="home_banner" id="home">
      <Image src={Homebanner} />
      <div className="home_info">
        <h2 className="main-title">
          Youpi<span>24</span> GmbH <br /> IT Consulting & Solutions
        </h2>
        <p className="title-text">
          Youpi24 est votre spécialiste dans le
          conseil et le développement d’applications Web et mobiles ainsi que le
          développement de logiciels d’entreprise. Nos produits et services sont
          à la pointe de l’innovation et facilitent le travail de nos clients
          dans divers secteurs tels que la Banque/Finance, les Assurances, la gouvernance,
          l’édition, le Marketing, le Gaming et le Commerce en ligne. Nous consultons
          et développons des solutions logicielles modernes pour nos clients,
          fournissant aussi bien des solutions d’entreprise scalables que des
          solutions du web 2.0 et du web 3.0 basées sur la blockchain.
        </p>
      </div>
    </div>
  );
};
export default HomeFr;

import { Icon } from "@iconify/react";
import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { Row, Col, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import "./Contact.css";

const ContactDu = () => {
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const USER_ID = process.env.REACT_APP_EMAILJS_USER_ID;

    const form = event.currentTarget;
    const formData = new FormData(event.target),
      formDataObj = Object.fromEntries(formData.entries());

    setValidated(true);

    if (form.checkValidity()) {
      emailjs.send(SERVICE_ID, TEMPLATE_ID, formDataObj, USER_ID).then(
        (result) => {
          toast.success("Email sent successfully.");
        },
        (error) => {
          toast.error("Something went wrong!!");
        }
      );
    }
  };
  return (
    <div className="company_banner" id="contact">
      <div className="company_info">
        <h2 className="main-title text-start text">
          Wir freuen uns auf Ihre Kontaktaufnahme.
        </h2>
        <p className="title-text mb-5 text-start mx-0">
          Haben Sie Fragen zu unseren Services oder Produkten, oder möchten Sie
          uns näher kennen lernen?
        </p>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row>
            <Col lg={6}>
              <Form.Group className="space">
                <Form.Label>Ihr Name (Pflichtfeld)</Form.Label>
                <Form.Control required type="text" name="name" />
                <Form.Control.Feedback type="invalid">
                Please enter Name.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Ihre E-Mail-Adresse (Pflichtfeld)</Form.Label>
                <Form.Control required type="email" name="email" />
                <Form.Control.Feedback type="invalid">
                  Please enter E-Mail-Adresse.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Betreff</Form.Label>
                <Form.Control required type="text" name="subject" />
                <Form.Control.Feedback type="invalid">
                  Please enter Betreff
                </Form.Control.Feedback>
              </Form.Group>
              <Button type="submit" variant="" className="btn-send desktop-btn">
                SEND <Icon icon="akar-icons:arrow-right" />
              </Button>
            </Col>
            <Col lg={6}>
              <Form.Group className="space">
                <Form.Label>Ihre Nachricht</Form.Label>
                <Form.Control required as="textarea" rows={3} name="message" />
                <Form.Control.Feedback type="invalid">
                  Please enter Ihre Nachricht.
                </Form.Control.Feedback>
              </Form.Group>
              <Button type="submit" variant="" className="btn-send mobile-btn">
                SEND <Icon icon="akar-icons:arrow-right" />
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};
export default ContactDu;

import React from "react";
/* import { Container, Image, Input } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Test } from "../../assets/images"; */
import "./Company.css";

const Company = () => {
  return (
     <div className="company_banner" id="company">
      <div className="company_info">
      <h2 className="main-title">The Company</h2>
      <p className="title-text mb-3">With over 2 decades of experience in the development of various applications, it is always our ambition to delight our customers with state-of-the-art software solutions and to create a real value in the conduct of their businesses.
</p>
      <p className="title-text mb-3">We deliver highly innovative applications for our B2B customers, whether in the context of our consulting services, or as in-house products in form of standard applications. In B2B business, we support our clients in their digital transformation efforts, either by adding resources directly to their IT teams or by taking full responsibility for developing some or all of their core business applications. In B2C area, we work in partnerships and contribute significantly to delivering disruptive Web 2.0 and Web 3.0 software to the general public.
</p>
      <p className="title-text">The satisfaction of our B2B and B2C customers is our main mission. That’s why we invest all our expertise and energy in developing high-quality applications as well as in providing the best service to our clients while assisting them in using these applications.
</p>
      </div>
     </div>
  );
};
export default Company;

import React from "react";
import { /* Container, */ Image, /*  Input, */ NavLink } from "react-bootstrap";
/* import { Link } from "react-router-dom"; */
import { Portfoliobg } from "../../assets/images";
import "./Portfolio.css";

const PortfolioDu = () => {
  return (
    <div className="portfolio_banner" id="portfolio">
      <Image src={Portfoliobg} />
      <div className="home_info">
        <h2 className="main-title">Portfolio</h2>
        <p className="title-text">
          Youpi24 IT Solutions & Consulting hat im Rahmen seiner Beratungssparte
          bereits umfangreiche Projekte mit namhaften Kunden aus den Bereichen
          Banking/Finanzen, Versicherungen, E-Commerce, Online Marketing,
          Verlagswesen und E-Governance abgewickelt.
        </p>
        <p className="title-text">
          Über unsere Beratungstätigkeiten hinaus sind wir offen für jede Partnerschaft, die darauf abzielt, unsere breit gefächerte Erfahrung
          für die Bereitstellung spezifischer B2C-Lösungen in den Bereichen E-Commerce, Bildung, E-Governance und Unterhaltung zu nutzen. Mehrere Partner haben bereits von diesem Know-how profitiert. Um ein aktuelles Beispiel zu nennen: wir spielen eine führende Rolle bei der Entwicklung des dezentralisierten Protokolls
          <a href="https://www.noracle.io" target="_blank" className="ms-2">NORACLE</a>, das von Anwendungen für Vorhersagen und Entscheidungsunterstützungen in Finanzbereich, Gaming und Metaversum verwendet wird.
        </p>
      </div>
    </div>
  );
};
export default PortfolioDu;

import React from "react";
import { /* Container, */ Image, /*  Input, */ NavLink } from "react-bootstrap";
/* import { Link } from "react-router-dom"; */
import { Portfoliobg } from "../../assets/images";
import "./Portfolio.css";

const Portfolio = () => {
  return (
    <div className="portfolio_banner" id="portfolio">
      <Image src={Portfoliobg} />
      <div className="home_info">
        <h2 className="main-title">
          Youpi<span>24</span> Portfolio
        </h2>
        <p className="title-text">
          Within its consulting division, Youpi24 IT Solutions & Consulting
          consistently delivers scalable projects to major clients in the
          banking/finance, insurance, e-commerce, online marketing, publishing
          industry, and for some government bodies.
        </p>
        <p className="title-text">
          Beyond our consulting activities, we also stay open to any partnership aimed at leveraging our diversified experience to deliver specific B2C solutions in e-commerce, education, governance, and entertainment. Several partners have already benefited from this expertise.
          To name a current example, we are leading collaborative efforts to build the decentralized protocol<a href="https://www.noracle.io" target="_blank" className="ms-2">NORACLE</a> which is designed to be used by prediction and decision support applications in finance, gaming and the metaverse.
        </p>
      </div>
    </div>
  );
};
export default Portfolio;
